import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { PillTextType, StatusPillColors } from '@web/components/StatusPill/hooks/usePillLocales';
import { useCanOpenTA } from '../hooks/useCanOpenTimeAssistant';

type SourceStatusInfo<T extends string = string> = {
  type: T;
  buttonText?: string;
  message: string;
  pillText: PillTextType;
  pillType: StatusPillColors;
};

export type AssistantStatusType = 'LAUNCH' | 'DOWNLOAD';

const useAssistantStatusLocale = (type: string | null | undefined): SourceStatusInfo<'LAUNCH' | 'DOWNLOAD'> => {
  const { _ } = useLingui();
  const canOpenTimeAssistant = useCanOpenTA();

  const launchText = canOpenTimeAssistant ? _(msg`Launch`) : undefined;

  switch (type) {
    case 'albert_inactive':
      return {
        type: 'LAUNCH',
        buttonText: launchText,
        message: _(msg`Your Time Assistant is either paused or not running right now. Please allow 3 hours after installation to connect.`),
        pillText: 'NoData',
        pillType: 'warning',
      };
    case 'albert_wrong_settings':
      return {
        type: 'LAUNCH',
        buttonText: launchText,
        message: _(msg`Your Time Assistant preferences are not configured correctly.`),
        pillText: 'Inactive',
        pillType: 'error',
      };
    case 'albert_good':
      return {
        type: 'LAUNCH',
        buttonText: launchText,
        message: _(msg`Successfully running the Time Assistant.`),
        pillText: 'Active',
        pillType: 'success',
      };
    case 'downloaded_not_running':
      return {
        type: 'LAUNCH',
        buttonText: launchText,
        message: _(
          msg`You've downloaded the Time Assistant but you either haven't logged in to the app or you haven't run the installer yet.`,
        ),
        pillText: 'NoData',
        pillType: 'warning',
      };
    case 'albert_not_yet_synced':
      return {
        type: 'DOWNLOAD',
        buttonText: _(msg`Download`),
        message: _(msg`Sage Time Assistant not installed.`),
        pillText: 'NoData',
        pillType: 'warning',
      };
    default:
      return {
        type: 'DOWNLOAD',
        buttonText: _(msg`Download`),
        message: _(msg`No data.`),
        pillText: 'Inactive',
        pillType: 'error',
      };
  }
};

export type EmailStatusType = 'RECONNECT' | 'DELETE' | 'CONNECT';

const useEmailStatusLocale = (type: string): SourceStatusInfo<EmailStatusType> => {
  const { _ } = useLingui();

  switch (type) {
    case 'email_inactive':
      return {
        type: 'RECONNECT',
        buttonText: _(msg`Re-connect`),
        message: _(msg`Connection initiated but no emails found. Please contact support.`),
        pillText: 'NoData',
        pillType: 'warning',
      };
    case 'email_good':
      return {
        type: 'DELETE',
        buttonText: _(msg`Delete`),
        message: _(msg`Successfully connected your email.`),
        pillText: 'Active',
        pillType: 'success',
      };
    case 'wait_for_emails':
    case 'waiting':
      return {
        type: 'DELETE',
        buttonText: _(msg`Delete`),
        message: _(msg`You recently connected your email. Please wait for up to 24 hours for the initial connection to complete.`),
        pillText: 'Active',
        pillType: 'success',
      };
    case 'no_emails_syncing':
      return {
        type: 'RECONNECT',
        buttonText: _(msg`Re-connect`),
        message: _(msg`Connection initiated. However, no emails have been retrieved yet. Please contact support.`),
        pillText: 'NoData',
        pillType: 'warning',
      };
    case 'no_email_token_try_again':
      return {
        type: 'RECONNECT',
        buttonText: _(msg`Re-connect`),
        message: _(msg`You unsuccessfully attempted to connect your email.`),
        pillText: 'Inactive',
        pillType: 'error',
      };
    case 'email_not_yet_synced':
      return {
        type: 'CONNECT',
        buttonText: _(msg`Connect`),
        message: _(msg`Email not connected`),
        pillText: 'NoData',
        pillType: 'warning',
      };
    default:
      return {
        type: 'CONNECT',
        buttonText: _(msg`Connect`),
        message: _(msg`No data.`),
        pillText: 'Inactive',
        pillType: 'error',
      };
  }
};

export type CalendarStatusType = 'RECONNECT' | 'DELETE' | 'CONNECT';

const useCalendarStatusLocale = (type: string): SourceStatusInfo<CalendarStatusType> => {
  const { _ } = useLingui();
  switch (type) {
    case 'cal_inactive':
      return {
        type: 'RECONNECT',
        buttonText: _(msg`Re-connect`),
        message: _(msg`You have connected your calendar but we don't have any events from you recently. Please contact support.`),
        pillText: 'NoData',
        pillType: 'warning',
      };
    case 'cal_good':
      return {
        type: 'DELETE',
        buttonText: _(msg`Delete`),
        message: _(msg`Successfully connected your calendar.`),
        pillText: 'Active',
        pillType: 'success',
      };
    case 'wait_for_cals':
    case 'waiting':
      return {
        type: 'DELETE',
        buttonText: _(msg`Delete`),
        message: _(msg`You recently connected your calendar. Please wait for up to 24 hours for the initial connection to complete.`),
        pillText: 'Active',
        pillType: 'success',
      };
    case 'no_cals_syncing':
      return {
        type: 'RECONNECT',
        buttonText: _(msg`Re-connect`),
        message: _(msg`Connection initiated but no events found. Please contact support.`),
        pillText: 'NoData',
        pillType: 'warning',
      };
    case 'no_cal_token_try_again':
      return {
        type: 'RECONNECT',
        buttonText: _(msg`Re-connect`),
        message: _(msg`You unsuccessfully attempted to connect your calendar. Please try again.`),
        pillText: 'Inactive',
        pillType: 'error',
      };
    case 'no_active_calendars':
      return {
        type: 'RECONNECT',
        buttonText: _(msg`Re-connect`),
        message: _(msg`No calendars selected. Connect a calendar below to retrieve calendar events.`),
        pillText: 'Inactive',
        pillType: 'error',
      };
    case 'cal_not_yet_synced':
      return {
        type: 'CONNECT',
        buttonText: _(msg`Connect`),
        message: _(msg`Calendar not connected.`),
        pillText: 'NoData',
        pillType: 'warning',
      };
    default:
      return {
        type: 'CONNECT',
        buttonText: _(msg`Connect`),
        message: _(msg`No data.`),
        pillText: 'Inactive',
        pillType: 'error',
      };
  }
};

export { useAssistantStatusLocale, useCalendarStatusLocale, useEmailStatusLocale };
