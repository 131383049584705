import { Colors, BasePalette } from 'carbon-react/lib/style/themes/base/base-theme.config';
import { PaletteFunction } from 'carbon-react/lib/style/palette';
import baseTheme from 'carbon-react/lib/style/themes/base';

import atOpacity from 'carbon-react/lib/style/utils/at-opacity';

export const baseColors: Colors = {
  error: baseTheme.colors.error ?? '#C7384F',
  focus: baseTheme.colors.focus ?? '#FFB500',
  info: baseTheme.colors.info ?? '#0073C2',
  loadingBarBackground: baseTheme.colors.loadingBarBackground ?? '#B3E0B3',
  placeholder: baseTheme.colors.placeholder ?? 'rgba(0,0,0,0.55)',
  primary: baseTheme.colors.primary ?? '#007D5A',
  secondary: baseTheme.colors.secondary ?? '#006046',
  tertiary: baseTheme.colors.tertiary ?? '#00402E',
  warning: baseTheme.colors.warning ?? '#E96400',
  warningText: baseTheme.colors.warningText ?? '#BA5000',
  white: baseTheme.colors.white ?? '#FFFFFF',
  destructive: { hover: baseTheme.colors.destructive?.hover ?? '#9F2D3F' },
};

export const buildPillColors = (colors: Colors, palette: BasePalette) => ({
  pillDefault: atOpacity(palette.slateTint!(60))(0.25),
  pillError: atOpacity(colors.error)(0.25),
  pillInfo: atOpacity(colors.info)(0.25),
  pillSuccess: atOpacity(palette.brilliantGreenShade!(50))(0.25),
  pillWarning: atOpacity(palette.carrotOrangeTint!(20))(0.25),
});

export type PaletteColor = string & PaletteFunction;

export interface SitColors extends Colors {
  pillDefault: string;
  pillError: string;
  pillInfo: string;
  pillSuccess: string;
  pillWarning: string;
}

export interface SitPalette extends BasePalette {
  amethyst: PaletteColor;
  amethystShade: PaletteFunction;
  amethystTint: PaletteFunction;
  black: PaletteColor;
  blackOpacity: (opacity: number | string) => string;
  blackShade: PaletteFunction;
  blackTint: PaletteFunction;
  brilliantGreen: PaletteColor;
  brilliantGreenShade: PaletteFunction;
  brilliantGreenTint: PaletteFunction;
  carrotOrange: PaletteColor;
  carrotOrangeShade: PaletteFunction;
  carrotOrangeTint: PaletteFunction;
  errorRed: PaletteColor;
  errorRedShade: PaletteFunction;
  errorRedTint: PaletteFunction;
  genericGreen: PaletteColor;
  genericGreenShade: PaletteFunction;
  genericGreenTint: PaletteFunction;
  gold: PaletteColor;
  goldShade: PaletteFunction;
  goldTint: PaletteFunction;
  navyBlue: PaletteColor;
  navyBlueShade: PaletteFunction;
  navyBlueTint: PaletteFunction;
  plum: PaletteColor;
  plumShade: PaletteFunction;
  plumTint: PaletteFunction;
  productBlue: PaletteColor;
  productBlueShade: PaletteFunction;
  productBlueTint: PaletteFunction;
  productGreen: PaletteColor;
  productGreenShade: PaletteFunction;
  productGreenTint: PaletteFunction;
  slate: PaletteColor;
  slateShade: PaletteFunction;
  slateTint: PaletteFunction;
  whiteOpacity: (opacity: number | string) => string;
}

// @TODO Remove once all CSS is converted to styled-components
// KEPT FOR UPDATE REFERENCE ONLY
// export const colors = {
//   primary: '#0073C2',
//   secondary: '#005C9A',
//   tertiary: '#004472',
//   loadingBarBackground: '#B3D6EF',
//   white: '#FFFFFF' as const, // Input, Table and Card background
//   error: '#C7384F',
//   focus: '#FFB500',
//   info: '#0073C2',
//   warning: '#E96400',
//   warningText: '#BA5000',
//   destructive: {
//     hover: '#9F2D3F',
//   },
//   placeholder: 'rgba(0,0,0,0.55)',
//   black: 'rgba(0, 0, 0, 1)',
//   black90: 'rgba(0, 0, 0, 0.9)',
//   black85: 'rgba(0, 0, 0, 0.85)',
//   black65: 'rgba(0, 0, 0, 0.65)',
//   black55: 'rgba(0, 0, 0, 0.55)',
//   black30: 'rgba(0, 0, 0, 0.3)',

//   // UTILITY COLORS
//   border: '#d8dfe3',
//   border2: '#668491',
//   border3: '#e5eaec',
//   blackhaze: '#fafbfb',
//   brilliantgreen: '#00dc00', // Only used for the Sage Logo
//   green20shade: '#00b000', // Success flashes and messages
//   errorred: '#c7384f', // Used for errors inproduct only
//   gold: '#ffb500', // Used for Focus states
//   carrot: '#e96400', // Warning messages
//   aegean: '#0073c2', // Info messages
//   aegeanhover: '#005c9a', // Info messages
//   slate: '#f2f5f6', // App, Dialog, Disabled Input background and hover  95 tint
//   slate90tint: '#e6ebed', // Disabled Button background and Separators
//   slate80tint: '#ccd6db', // Text highlight and Table Row hover
//   slate60tint: '#99adb6', // Base for shadows (with opacity)
//   slate40tint: '#668592', // Input borders and Icons without focus
//   slate20tint: '#335c6d', // Icons with focus
//   grays1: '#5e5e5e',
//   disabledButtonColor: '#0000004d',

//   // PILL COLORS
//   pilldefault: 'rgba(153, 173, 182, 0.25)',
//   pillerror: 'rgba(199, 56, 79, 0.25)',
//   pillinfo: 'rgba(0, 115, 193, 0.25)',
//   pillsuccess: 'rgba(0, 110, 0, 0.25)',
//   pillwarning: 'rgba(237, 131, 51, 0.25)',
// };
