import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'rc-input-number/assets/index.css';
import 'rc-menu/assets/index.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-toastify/dist/ReactToastify.min.css';

import './helpers/initialize-client-shared';
import './styles/index.scss';

import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import type { SetupWorker } from 'msw/browser';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import AppProviders from './AppProviders';
import App from './containers/App';
import { CookiesRequirements } from './containers/Errors/CookiesRequirements';
import { buildLaunchDarklyConfigWithUser } from './launchDarkly/launchDarklyConfig';

const LOADING_SCREEN_SELECTOR = 'assets-loading';
const HIDE_CLASS = 'hide';

function hideLoadingScreen() {
  const loadingScreen = document.getElementsByClassName(LOADING_SCREEN_SELECTOR)[0];
  loadingScreen?.classList.add(HIDE_CLASS);
}

const isSessionStorageEnabled = () => {
  try {
    // Try to access get Item.
    //if disable an error will be thrown.
    sessionStorage.getItem('_');
    return true;
  } catch {
    return false;
  }
};

if (process.env.NODE_ENV !== 'production') {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000);
  });
  loadErrorMessages();
  loadDevMessages();
}

if (import.meta.env.NODE_ENV === 'development' && !import.meta.env.VITE_APP_MSW_DISABLED) {
  // import("./__mocks__/browser").then(({ worker }) => {
  //   worker.start();
  // });
  // ^ isn't as accurate because we need to load msw synchronously
  // to catch the first page request (404 otherwise).
  // There is no significant performance difference between import and require.

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const worker = require('./__mocks__/browser').worker as SetupWorker;

  worker.start({
    onUnhandledRequest: 'bypass',
    quiet: import.meta.env.VITE_APP_LOG_MSW !== 'true',
  });
}

hideLoadingScreen();

// Make feature flags available before the app is initially rendered
(async () => {
  if (!isSessionStorageEnabled()) {
    ReactDOM.render(<CookiesRequirements />, document.getElementById('root'));
    return;
  }

  try {
    const LDProvider = await asyncWithLDProvider(buildLaunchDarklyConfigWithUser());

    ReactDOM.render(
      <StrictMode>
        <LDProvider>
          <AppProviders>
            <App />
          </AppProviders>
        </LDProvider>
      </StrictMode>,
      document.getElementById('root'),
    );
  } catch (error) {
    console.error(error);
    // on error simply render without LDProvider
    ReactDOM.render(
      <StrictMode>
        <AppProviders>
          <App />
        </AppProviders>
      </StrictMode>,
      document.getElementById('root'),
    );
  } finally {
    if (import.meta.env.NODE_ENV === 'development') {
      import('./reportWebVitals')
        .then(({ reportWebVitals }) => reportWebVitals(console.log))
        .catch((error) => {
          console.error('Failed to load reportWebVitals', error);
        });
    }
  }
})();
