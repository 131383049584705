import { currentOS, OSPlatforms } from '@web/helpers/operatingSystem';

export function canOpenTA(version: string, os: OSPlatforms) {
  // 3.0.0-3.0.6 releases of TA cannot open the time assistant using the `sittracker://param` URL scheme.
  // 3.0.7 adds support.
  // In the future, we can check which version of TA the user has installed and conditionally return `true` if the user has a version of TA that supports the URL scheme.
  const unsupportedOSs = [OSPlatforms.not_supported];
  // This technically works as a string comparison
  return !unsupportedOSs.includes(os) && version.localeCompare('3.0.7') >= 0;
}

export function useCanOpenTA() {
  const version = '3.0.0';
  return canOpenTA(version, currentOS);
}
