import { GetApiAccountsResponse, queryKeyFn, usePutApiAccount } from '@sit/client-shared';
import { useQueryClient } from '@tanstack/react-query';
import immer from 'immer';

type PutApiAccountContext = {
  previousAccounts: GetApiAccountsResponse | undefined;
};

export function usePutApiAccountOptimistic() {
  const queryClient = useQueryClient();
  const queryKey = queryKeyFn({
    path: '/accounts',
    operationId: 'getApiAccounts',
    variables: {},
  });
  return usePutApiAccount({
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousAccounts = queryClient.getQueryData<GetApiAccountsResponse | undefined>(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<GetApiAccountsResponse | undefined>(queryKey, (old) => {
        // Edge case where we don't have a previous value to update.
        // Without this `if` statement, we would invalidly assign partial state.
        // This avoid errors like "Cannot read property 'id' of undefined".
        if (!old) return old;
        const accountIndex = old.findIndex((account) => `${account.id}` === variables.pathParams.accountId);
        if (accountIndex === -1) return old;
        return immer(old, (draft) => {
          Object.assign(draft[accountIndex]!, variables.body);
        });
      });

      // Return a context object with the snapshotted value
      return { previousAccounts } as PutApiAccountContext;
    },
    onSuccess: (_data, variables) => {
      if (variables.pathParams.accountId === 'tracker') {
        return queryClient.invalidateQueries({
          queryKey: queryKeyFn({
            path: '/user-settings',
            operationId: 'getApiUserSettings',
            variables,
          }),
        });
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(queryKey, (context as PutApiAccountContext).previousAccounts);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
}
