import { Cluster, ClusterResponse, GetApiTimesheetsByUuidEntriesResponse, TimesheetEntryWithDimensionValues } from '@sit/client-shared';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { includeIntacctId } from '../../helpers/utils';

function filterAssignedClusters<C extends Cluster>(
  clusters: C[],
  entries: GetApiTimesheetsByUuidEntriesResponse | undefined,
  timesheetId: string,
): C[] {
  return clusters.filter((c) => {
    if (c.timesheet_entry_id) {
      const existingEntry = entries?.find((e) => e.id === c.timesheet_entry_id);
      return existingEntry?.timesheet_id === timesheetId;
    }
    return !!c.miscellaneousReason;
  });
}

function filterUnassignedClusters<C extends Cluster>(clusters: C[]): C[] {
  return clusters.filter((c) => !(c && (c.timesheet_entry_id || c.miscellaneousReason)));
}

function sortClustersHelper<C extends Cluster>(clusters: C[]): C[] {
  return clusters.sort((a, b) => {
    const aMeta = a.metaData && typeof a.metaData === 'object' ? a.metaData : {};
    const bMeta = b.metaData && typeof b.metaData === 'object' ? b.metaData : {};
    if (a.source === 'calendar') {
      if (b.source !== 'calendar') return -1;
      const diff = moment(aMeta.timestamp_end || a.created_at).diff(moment(bMeta.timestamp_end || b.created_at));
      return diff !== 0 ? -diff : a.id.localeCompare(b.id);
    }
    if (b.source === 'calendar') {
      return -1;
    }
    if (a.source === 'email') {
      if (b.source !== 'email') return -1;
      const diff = moment(aMeta.last_timestamp_end || a.created_at).diff(moment(bMeta.last_timestamp_end || b.created_at));
      return diff !== 0 ? -diff : a.id.localeCompare(b.id);
    }
    if (b.source === 'email') {
      return 1;
    }
    if (a.source === 'activity') {
      if (b.source !== 'activity') return 1;
      const diff = moment(aMeta.last_timestamp_end || a.created_at).diff(moment(bMeta.last_timestamp_end || b.created_at));
      return diff !== 0 ? -diff : a.id.localeCompare(b.id);
    }
    // fallback
    const diff = moment(a.created_at).diff(moment(b.created_at));
    return diff !== 0 ? -diff : a.id.localeCompare(b.id);
  });
}

function groupAndSortClusters<C extends Cluster>(clusters: C[]) {
  const groupedByDate = clusters.reduce<Record<string, C[]>>((groups, cluster) => {
    const date = cluster.date;
    if (!groups[date]) groups[date] = [];
    groups[date].push(cluster);
    return groups;
  }, {});

  const groupedByDateArray = Object.entries(groupedByDate)
    .map(([date, cluster]) => ({
      date,
      clusters: sortClustersHelper(cluster),
    }))
    .sort((a, b) => {
      const diff = moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD'));
      return diff <= 0 ? 1 : -1;
    });
  return groupedByDateArray;
}

function includeIntacctIdInClusterDimensionNames(cluster: ClusterResponse) {
  const clusterCopy = cloneDeep(cluster);
  if (clusterCopy.predictedClientId && clusterCopy.client?.name && clusterCopy.client?.externalId) {
    clusterCopy.client = includeIntacctId(clusterCopy.client);
  }
  if (clusterCopy.predictedProjectId && clusterCopy.project?.name && clusterCopy.project?.externalId) {
    clusterCopy.project = includeIntacctId(clusterCopy.project);
  }
  if (clusterCopy.predictedTaskId && clusterCopy.task?.name && clusterCopy.task?.externalId) {
    clusterCopy.task = includeIntacctId(clusterCopy.task);
  }
  if (clusterCopy.predictedItemId && clusterCopy.item?.name && clusterCopy.item?.externalId) {
    clusterCopy.item = includeIntacctId(clusterCopy.item);
  }
  return clusterCopy;
}

function includeIntacctIdInEntryDimensionNames(entry: TimesheetEntryWithDimensionValues): TimesheetEntryWithDimensionValues;
function includeIntacctIdInEntryDimensionNames(entry: null | undefined): null;
function includeIntacctIdInEntryDimensionNames(
  entry: TimesheetEntryWithDimensionValues | null | undefined,
): TimesheetEntryWithDimensionValues | null {
  if (!entry?.id) return null;
  const entryCopy = cloneDeep(entry);
  if (entryCopy.confirmedClientId && entryCopy.client?.name && entryCopy.client?.externalId) {
    entryCopy.client = includeIntacctId(entryCopy.client);
  }
  if (entryCopy.confirmedProjectId && entryCopy.project?.name && entryCopy.project?.externalId) {
    entryCopy.project = includeIntacctId(entryCopy.project);
  }
  if (entryCopy.confirmedTaskId && entryCopy.task?.name && entryCopy.task?.externalId) {
    entryCopy.task = includeIntacctId(entryCopy.task);
  }
  if (entryCopy.confirmedItemId && entryCopy.item?.name && entryCopy.item?.externalId) {
    entryCopy.item = includeIntacctId(entryCopy.item);
  }
  // TimeType does not have intacctId
  entryCopy.dimensionsValues = entryCopy.dimensionsValues?.map((dimValue) => includeIntacctId(dimValue));
  return entryCopy;
}

export {
  filterAssignedClusters,
  filterUnassignedClusters,
  groupAndSortClusters,
  includeIntacctIdInClusterDimensionNames,
  includeIntacctIdInEntryDimensionNames,
};
